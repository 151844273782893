import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./grid.scss";

export const Grid = ({ className, children, ...props }) => (
  <div className={classNames("grid", className)} {...props}>
    <div className="grid__inner">{children}</div>
  </div>
);
export const GridItem = props => <div className="grid__item" {...props} />;

const sharedProps = {
  children: PropTypes.node.isRequired,
};

Grid.propTypes = {
  ...sharedProps,
  className: PropTypes.string,
};

GridItem.propTypes = {
  ...sharedProps,
};
