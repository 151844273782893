import React, { useState, useEffect, useMemo } from "react";
import { Link, graphql } from "gatsby";
import { AiOutlineSortAscending, AiOutlineSortDescending } from "react-icons/ai";
import classNames from "classnames";

// eslint-disable-next-line no-unused-vars
import { pagesDataQuery } from "../../queries/queries";

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";
import { Grid, GridItem } from "../../components/grid/grid";
import {
  Table,
  TableThead,
  TableTbody,
  TableTr,
  TableTh,
  TableTd,
} from "../../components/table/table";
import Card from "../../components/card/card";
import VisuallyHidden from "../../components/visually-hidden/index";
import { TextInput } from "../../components/text-input/text-input";
// import { Radio } from "../../components/radio/radio";

import "./index.scss";

const compareNames = (a, b) => {
  if (a.name > b.name) {
    return 1;
  }

  if (a.name < b.name) {
    return -1;
  }

  return 0;
};

const compareErrors = (a, b) => {
  if (a.count > b.count) {
    return 1;
  }

  if (a.count < b.count) {
    return -1;
  }

  return 0;
};

function parseData(pagesData, testData, imagesData) {
  const municipalities = Object.values(
    pagesData.reduce((municipalities, { node: page }) => {
      const municipality = municipalities[page.group] || {
        id: page.id,
        group: page.group,
        name: page.name,
        count: 0,
        slug: page.slug,
        image: imagesData.find(item => item.node.resize.originalName === `${page.slug}.png`).node
          .resize,
        pageCount: 0,
      };

      municipalities[page.group] = municipality;

      const municipalitySibling = testData.find(datum => page.slug === datum.parent.name);

      const errorCount = municipalitySibling.data[0].code ? municipalitySibling.data.length : 0;

      municipality.pageCount = municipality.pageCount + 1;
      municipality.count = municipality.count + errorCount;

      return municipalities;
    }, {})
  );

  const sortedMunicipalities = municipalities.sort(compareErrors);

  let rank = 1;
  let lastErrorCount = 0;
  const rankedMunicipalities = sortedMunicipalities.map((item, index) => {
    if (item.count !== lastErrorCount) {
      rank = index + 1;
      lastErrorCount = item.count;
    }

    return {
      rank,
      ...item,
    };
  });

  return rankedMunicipalities;
}

const OverviewPage = ({ data }) => {
  const pagesData = data.allPagesJson.edges;
  const testData = data.allCsvCsv.nodes;
  const imagesData = data.allImageSharp.edges;
  const links = useMemo(() => parseData(pagesData, testData, imagesData).sort(compareNames), [
    pagesData,
    testData,
    imagesData,
  ]);
  const [sortingBy, setSortingBy] = useState("name");
  const [view, setView] = useState(false);
  const [visibleLinks, setVisibleLinks] = useState(links);
  const [sortingAsc, setSortingAsc] = useState(true);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    const compare = sortingBy === "name" ? compareNames : compareErrors;
    const sortFn = sortingAsc ? compare : (a, b) => -1 * compare(a, b);
    setVisibleLinks(prevLinks => [...prevLinks].sort(sortFn));
  }, [sortingBy, sortingAsc]);

  useEffect(() => {
    setVisibleLinks(() => {
      const filterRegex = new RegExp(filter, "i");

      return links.filter(link => {
        return filterRegex.test(link.name);
      });
    });
  }, [filter, links]);

  return (
    <Layout>
      <SEO title="Oversigt over kommuner" />
      <h2>Oversigt over kommuner</h2>

      <>
        <div className="tool-bar">
          <div className="tool-bar__item">
            <TextInput
              label="Søg efter kommune"
              id="filter"
              aria-controls="municipalities-list municipalities-table"
              onChange={e => setFilter(e.target.value)}
              isSearch
            >
              <VisuallyHidden aria-live="polite" aria-atomic="true">
                {visibleLinks.length} af {pagesData.length / 3}
              </VisuallyHidden>
            </TextInput>
          </div>

          <div className="tool-bar__item">
            <div className="tool-bar-button-group">
              <div id="sort-by-label" className="tool-bar-label">
                Sortér efter
              </div>

              <button
                id="sort-by-name"
                className="tool-bar-button"
                type="button"
                aria-pressed={sortingBy === "name"}
                onClick={() => setSortingBy("name")}
                aria-labelledby="sort-by-label sort-by-name"
              >
                <span className="tool-bar-button__inner">Navn</span>
              </button>
              <button
                id="sort-by-errors"
                className="tool-bar-button"
                type="button"
                aria-pressed={sortingBy === "errors"}
                onClick={() => setSortingBy("errors")}
                aria-labelledby="sort-by-label sort-by-errors"
              >
                <span className="tool-bar-button__inner">Antal fejl</span>
              </button>
            </div>

            <div className="tool-bar-button-group">
              <div id="show-as-label" className="tool-bar-label">
                Vis som
              </div>

              <button
                id="show-as-list"
                className={classNames("tool-bar-button", {
                  "tool-bar-button--is-active": !view,
                })}
                onClick={() => {
                  setView(false);
                }}
                aria-labelledby="show-as-label show-as-list"
              >
                <span className="tool-bar-button__inner">Liste</span>
              </button>

              <button
                id="show-as-table"
                className={classNames("tool-bar-button", {
                  "tool-bar-button--is-active": view,
                })}
                onClick={() => {
                  setView(true);
                }}
                aria-labelledby="show-as-label show-as-table"
              >
                <span className="tool-bar-button__inner">Tabel</span>
              </button>
            </div>

            <div className="tool-bar-button-group">
              <button
                className="tool-bar-button tool-bar-button--squared"
                onClick={() => {
                  setSortingAsc(prevState => !prevState);
                }}
              >
                <span className="tool-bar-button__inner">
                  {sortingAsc ? (
                    <>
                      <AiOutlineSortAscending aria-hidden="true" />
                      <VisuallyHidden>Sortér fra å-a</VisuallyHidden>
                    </>
                  ) : (
                    <>
                      <AiOutlineSortDescending aria-hidden="true" />
                      <VisuallyHidden>Sortér fra a-å</VisuallyHidden>
                    </>
                  )}
                </span>
              </button>
            </div>
          </div>
        </div>

        {view ? (
          <Table id="municipalities-table">
            <colgroup>
              <col className="col-rank" />
              <col />
              <col className="col-number" />
            </colgroup>

            <TableThead>
              <TableTr>
                <TableTh scope="col" alignRight>
                  #
                </TableTh>
                <TableTh scope="col">Kommune</TableTh>
                <TableTh scope="col" alignRight>
                  Fejl
                </TableTh>
              </TableTr>
            </TableThead>

            <TableTbody>
              {visibleLinks.length > 0 ? (
                visibleLinks.map(row => {
                  return (
                    <TableTr key={row.id}>
                      <TableTd alignRight thText="Nummer">
                        {row.rank}
                      </TableTd>
                      <TableTd thText="Kommune">
                        <Link to={`/${row.slug}`}>{row.name}</Link>
                      </TableTd>
                      <TableTd alignRight thText="Fejl">
                        {row.count.toLocaleString("da-dk")}
                      </TableTd>
                    </TableTr>
                  );
                })
              ) : (
                <p>Ingen tests fundet</p>
              )}
            </TableTbody>
          </Table>
        ) : (
          <Grid id="municipalities-list">
            {visibleLinks.length > 0 ? (
              visibleLinks.map(row => {
                return (
                  <GridItem key={row.id}>
                    <Card
                      as="a"
                      href={`/${row.slug}`}
                      name={row.group}
                      aria-label={`Se detaljer for ${row.group} kommune`}
                      errors={row.count}
                      image={row.image}
                      pageCount={row.pageCount}
                    />
                  </GridItem>
                );
              })
            ) : (
              <GridItem>
                <p>Ingen tests fundet</p>
              </GridItem>
            )}
          </Grid>
        )}
      </>
    </Layout>
  );
};

export default OverviewPage;

export const query = graphql`
  query indexPageQuery {
    allPagesJson {
      edges {
        node {
          ...pagesDataQueryFragment
        }
      }
    }

    allCsvCsv {
      nodes {
        data {
          code
        }
        parent {
          ... on File {
            name
          }
        }
      }
    }

    allImageSharp {
      edges {
        node {
          resize(width: 550, height: 310, cropFocus: NORTH, toFormat: JPG) {
            originalName
            src
          }
        }
      }
    }
  }
`;
