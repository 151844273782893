import React from "react";
import PropTypes from "prop-types";
import { FiAlertCircle, FiCheckCircle } from "react-icons/fi";

import "./card.scss";

export const Card = ({ as: Comp, name, errors, image, pageCount, ...props }) => {
  return (
    <Comp className="card" {...props}>
      {errors > 0 ? (
        <FiAlertCircle className="card__icon card__icon--error" aria-hidden="true" />
      ) : (
        <FiCheckCircle className="card__icon card__icon--success" aria-hidden="true" />
      )}
      <div className="card__image-wrapper">
        <img className="card__image" src={image.src} alt="" loading="lazy" />
      </div>
      <dl className="card__content">
        <div className="card__title">
          <dt className="">Navn</dt>
          <dd>{name}</dd>
        </div>
        <div className="card__errors card__errors--align-right">
          <dt>
            Fejl – fordelt på {pageCount} side{pageCount > 1 ? "r" : ""}
          </dt>
          <dd>{errors.toLocaleString("da-dk")}</dd>
        </div>
      </dl>
    </Comp>
  );
};

Card.propTypes = {
  name: PropTypes.string.isRequired,
  errors: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  as: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string]),
  image: PropTypes.object,
};

Card.defaultProps = {
  component: "div",
};

export default Card;
